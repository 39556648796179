export enum GreenBookStatus {
  CANDIDATE = 'candidate',
  RESTRICTED = 'restricted',
  REJECTED = 'rejected',
  STANDARD_RETIRED = 'standard retired',
  STANDARD_VALIDATED = 'standard validated',
  UNKNOWN = 'unknown',
}

export enum BlueBookStatus {
  VALID = 'valid',
  NOT_VALID = 'not-valid',
  NOT_DEFINED = 'not-defined',
  UNKNOWN = 'unknown',
}

export enum SoftwareCategory {
  MAINFRAME = 'Mainframe',
  DEVELOPER_EXPERIENCE = 'Developer experience',
  DEVELOPER_TOOL = 'Developer tool',
  BPM = 'BPM',
  SOFA = 'SOFA',
  BS_SUPPORT = 'BS Support',
  BI = 'BI',
  WORKPLACE = 'Workplace',
  OTHER = 'Other',
}

export enum CategoryImpact {
  SOFA = 'sofa',
  API_PLATEFORM = 'api-plateform',
  APPLICATION_SERVER = 'application-server',
  BI_ANALYTICS = 'bi',
  BS_SUPPORT = 'bs-support',
  BUNSINESS_PROCESS_MGT = 'bpm',
  COMPUTE_CONTAINER = 'compute-container',
  CONNECTIVITY = 'connectivity',
  DATA_INTEGRATION_INTEROPERABILITY = 'data-integration-interoperability',
  DATA_MANAGEMENT = 'data-management',
  DATABASE = 'datatbase',
  DEVLOPPER_EXPERIENCE = 'devlopper-experience',
  DOCUMENT_CONTENT_MGT = 'document-content-mgt',
  EVENT_EXCHANGE = 'event-exchange',
  INFORMATION_MGT = 'information-mgt',
  IS_SECURITY_SERVICES = 'security-services',
  MGT_OF_IS_SERVICES = 'mgt-services',
  OBSERVABILITY = 'observability',
  STORAGE = 'storage',
  WORKPLACE = 'workplace',
}

export enum ControlTeamPosition {
  OK = 'ok',
  BUS = 'bus',
  NOK = 'nok',
  NOK_DEROGATED = 'nok-derogated',
  NOK_REMEDIATED = 'nok-remediated',
  NOK_STUDY = 'nok-study',
  NOT_SOFTWARE = 'not-software',
}

export enum ComplianceAssessment {
  TO_DO = 'to-do',
  TO_CHECK = 'to-check',
  TO_DECOM = 'to-decom',
  TO_RENEW = 'to-renew',
  TO_DECREASE = 'to-decrease',
  TO_TRANSFER = 'to-transfer',
  VALIDATED = 'validated',
}

export enum StandardCompliant {
  NOT_SOFTWARE = 'not-software',
  // TODO To complet list
}

export enum ContractType {
  LOCATION = 'location',
  LICENCE = 'licence',
  MAINTENANCE = 'maintenance',
  OTHER = 'other',
}

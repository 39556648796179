import { getRequestHeaders, IcContact } from '@sgwt/react-shared-libs';
import { IQueryParams } from '../hooks/useQueryParams';
import {
  AMOUNT_IN_EUR_LIST,
  IAmountInEUR,
  ITEM_PER_PAGE,
  ItemWithIntervalDate,
  listTerminationDates,
} from '../shared/constants';
import { BaseBlueBook, BlueBook, BlueBookList, GreenBookList } from '../types/Books';
import {
  Contract,
  ContractAssessmentList,
  ContractFullDetails,
  ContractFullHistory,
  ControlTeamAssessment,
  LocalReviewerAssessment,
} from '../types/Contract';
import { FiltersValues, ListOfValues } from '../types/filters';
import { PaginedQuery } from '../types/PaginedQuery';
import { Software, SoftwareFullHistory, SoftwareSummaryList } from '../types/Software';

export const API_BASE_URL = window.globalConfiguration.api;
export const API_BASE_VERSION = 'v1';

async function baseHttpRequest<T>(
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  body?: BodyInit,
  responseTransformer?: (response: Response) => Promise<T>
): Promise<T> {
  let json;
  const response = await fetch(url, {
    method,
    headers: {
      ...getRequestHeaders(),
      'Content-Type': 'application/json',
    },
    body,
  });
  if (!responseTransformer) {
    json = await response.json();
    if (!response.ok) {
      throw new Error(`Error: ${json.statusCode} ${response.statusText} - ${json.message}`);
    }
    return json;
  }
  return responseTransformer(response);
}

const Api = {
  get: <TResponse>(url: string) => baseHttpRequest<TResponse>('get', url),
  post: <TResponse>(url: string, body?: BodyInit) => baseHttpRequest<TResponse>('post', url, body),
  put: <TResponse>(url: string, body?: BodyInit) => baseHttpRequest<TResponse>('put', url, body),
  delete: <TResponse>(url: string, body?: BodyInit) => baseHttpRequest<TResponse>('delete', url, body),
};

function formatData(key: string, data?: (string | number | undefined)[]) {
  if (data && data.filter((item: string | number | undefined) => item).length > 0) {
    return `${key}=${data.join(`&${key}=`)}`;
  }
  return '';
}

function getRequestParamsFromQueryParams(queryParams: IQueryParams): string {
  const amountInEUR: IAmountInEUR | undefined = AMOUNT_IN_EUR_LIST.find(
    (item: IAmountInEUR) => item.key === queryParams.amountInEur
  );
  const terminationDates: ItemWithIntervalDate | undefined = listTerminationDates().find(
    (item: ItemWithIntervalDate) => item.key === queryParams.terminationDates
  );
  let requestParams: string[] = [];
  requestParams.push(formatData('search', [queryParams.search]));
  requestParams.push(formatData('greenbooks', queryParams.greenbook));
  requestParams.push(formatData('tribes', queryParams.tribes));
  requestParams.push(formatData('editors', queryParams.editor));
  requestParams.push(formatData('peoples', queryParams.people));
  requestParams.push(formatData('countries', queryParams.country));
  requestParams.push(formatData('amountInEUR', [amountInEUR?.interval?.min, amountInEUR?.interval?.max]));
  requestParams.push(
    formatData('terminationDates', [terminationDates?.interval?.min, terminationDates?.interval?.max])
  );
  requestParams.push(formatData('controlTeamPosition', queryParams.controlTeamPosition));
  requestParams.push(formatData('complianceAssessment', queryParams.complianceAssessment));
  requestParams.push(formatData('applicationId', [queryParams.applicationId]));
  requestParams.push(formatData('orderBy', [queryParams.orderBy]));
  requestParams = requestParams.filter((requestParam: string) => requestParam !== '');
  return `&${requestParams.join('&')}`;
}

// -------------------------------------------
// CONTRACTS
//#region
// -------------------------------------------
const getContracts = async (queryParams: IQueryParams): Promise<PaginedQuery<Contract>> => {
  // Build the correct url with params
  const requestParams: string = await getRequestParamsFromQueryParams(queryParams);
  const url = `${API_BASE_URL}/${API_BASE_VERSION}/contracts/?limit=${ITEM_PER_PAGE}&page=${queryParams.page}${requestParams}`;
  return Api.get(url);
};

const getContract = async (id: string): Promise<ContractFullDetails> => {
  if (!id) {
    throw new Error(`This ID "${id}" isn't a valid ID`);
  }
  return Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/contracts/${id}`);
};

const getContractHistory = async (id: string): Promise<ContractFullHistory> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/contracts/${id}/history`);

const getAssessments = async (): Promise<ContractAssessmentList> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/contracts/assessments`);

const createContract = async (contract: Contract): Promise<Contract> =>
  Api.post(
    `${API_BASE_URL}/${API_BASE_VERSION}/contracts`,
    JSON.stringify({
      ...contract,
      // Do not allow an empty value as the `reference` should unique in DB...
      reference: contract.reference || null,
      amount: Number(contract.amount),
      amountInEUR: Number(contract.amountInEUR),
      yearlyValorization: Number(contract.yearlyValorization),
      softwareId: contract.software.id,
      software: undefined,
    })
  );

const saveContract = async (contract: Contract): Promise<Contract> => {
  if (!contract.id) {
    throw new Error(`This ID "${contract.id}" isn't a valid ID`);
  }
  return Api.put(
    `${API_BASE_URL}/${API_BASE_VERSION}/contracts/${contract.id}`,
    JSON.stringify({
      ...contract,
      // Do not allow an empty value as the `reference` should unique in DB...
      reference: contract.reference || null,
      amount: Number(contract.amount),
      amountInEUR: Number(contract.amountInEUR),
      yearlyValorization: Number(contract.yearlyValorization),
      softwareId: contract.software.id,
      software: undefined,
    })
  );
};

const deleteContract = async (contract: Contract): Promise<Contract> => {
  if (!contract.id) {
    throw new Error(`This ID "${contract.id}" isn't a valid ID`);
  }
  return Api.delete(`${API_BASE_URL}/${API_BASE_VERSION}/contracts/${contract.id}`);
};

const changeContractOfSoftware = (contractId: string, softwareId: string) =>
  Api.put(
    `${API_BASE_URL}/${API_BASE_VERSION}/contracts/${contractId}/software`,
    JSON.stringify({
      contractId,
      softwareId,
    })
  );

const assessContract = (assessment: LocalReviewerAssessment | ControlTeamAssessment) =>
  Api.put(
    `${API_BASE_URL}/${API_BASE_VERSION}/contracts/${assessment.id}/assessment`,
    JSON.stringify({ ...assessment })
  );

// #endregion

// -------------------------------------------
// SOFTWARES
// #region
// -------------------------------------------
const getAllSoftwares = async (): Promise<PaginedQuery<Software>> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/softwares/?limit=500&page=1`);

const getSoftwares = (queryParams: IQueryParams): Promise<PaginedQuery<Software>> => {
  let requestParams: string[] = [];
  requestParams.push(formatData('search', [queryParams.search]));
  requestParams.push(formatData('editors', queryParams.editor));
  requestParams.push(formatData('greenbooks', queryParams.greenbook));
  requestParams = requestParams.filter((requestParam: string) => requestParam !== '');
  const url = `${API_BASE_URL}/${API_BASE_VERSION}/softwares?limit=${ITEM_PER_PAGE}&page=${
    queryParams.page ?? 1
  }&${requestParams.join('&')}`;
  return Api.get(url);
};

const getSoftware = async (id: string): Promise<Software> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/softwares/${id}`);

const getSoftwareHistory = async (id: string): Promise<SoftwareFullHistory> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/softwares/${id}/history`);

const getSoftwaresSummary = async (): Promise<SoftwareSummaryList> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/softwares/summary`);

const updateSoftware = async (software: Software): Promise<Software> => {
  if (!software.id) {
    throw new Error(`This ID "${software.id}" isn't a valid ID`);
  }
  return baseHttpRequest(
    'put',
    `${API_BASE_URL}/${API_BASE_VERSION}/softwares/${software.id}`,
    JSON.stringify({
      ...software,
      contracts: undefined,
    })
  );
};

const createSoftware = async (software: Software): Promise<Software> =>
  Api.post(
    `${API_BASE_URL}/${API_BASE_VERSION}/softwares`,
    JSON.stringify({
      ...software,
      contracts: undefined,
      id: undefined,
    })
  );

const deleteSoftware = async (id: string): Promise<void> =>
  Api.delete(`${API_BASE_URL}/${API_BASE_VERSION}/softwares/${id}`);

// #endregion

// -------------------------------------------
// BLUE BOOK
// #region
// -------------------------------------------
const getGreenBook = async (): Promise<GreenBookList> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/greenbooks?size=1000`);

// #endregion

// -------------------------------------------
// BLUE BOOK
// #region
// -------------------------------------------
const getBlueBook = async (): Promise<BlueBookList> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/bluebooks?size=1000`);

const createBlueBook = async (bluebook: BaseBlueBook): Promise<BlueBook> =>
  Api.post(`${API_BASE_URL}/${API_BASE_VERSION}/bluebooks`, JSON.stringify(bluebook));

const updateBlueBook = async (bluebook: BlueBook): Promise<BlueBook> =>
  Api.put(`${API_BASE_URL}/${API_BASE_VERSION}/bluebooks/${bluebook.id}`, JSON.stringify(bluebook));

const deleteBlueBook = async (id: number): Promise<BlueBook> =>
  Api.delete(`${API_BASE_URL}/${API_BASE_VERSION}/bluebooks/${id}`);

const importBlueBookFile = async ({ file }: { file: File }): Promise<void> => {
  const body = new FormData();
  body.append('file', file);
  const response = await fetch(`${API_BASE_URL}/${API_BASE_VERSION}/bluebooks/import`, {
    method: 'post',
    headers: getRequestHeaders(),
    body,
  });
  if (response.status === 409) {
    const parsedResponse: Error = await response.json();
    throw new Error(`${response.status} ${parsedResponse.message.toString()}`);
  }
  if (!response.ok) {
    throw response;
  }
};

// #endregion

// -------------------------------------------
// IMPORT / EXPORT EXCEL FILES
// #region
// -------------------------------------------

const importXlsxFile = async ({
  file,
  conflict,
  dateFormat,
}: {
  file: File;
  conflict?: 'ignore' | 'overwrite';
  dateFormat?: 'dd/mm/yyyy' | 'mm/dd/yyyy';
}): Promise<void> => {
  const body = new FormData();
  body.append('file', file);
  const response = await fetch(
    `${API_BASE_URL}/${API_BASE_VERSION}/xlsx?${conflict ? `overwrite=${conflict}` : ''}${
      dateFormat ? `&dateFormat=${dateFormat}` : ''
    }`,
    {
      method: 'post',
      headers: getRequestHeaders(),
      body,
    }
  );
  if (response.status === 409) {
    const parsedResponse: Error = await response.json();
    throw new Error(`${response.status} ${parsedResponse.message.toString()}`);
  }
  if (!response.ok) {
    throw response;
  }
};

const exportXlsxFile = async (queryParams: IQueryParams): Promise<void> => {
  const requestParams: string = await getRequestParamsFromQueryParams(queryParams);
  // Build the correcturl with params
  const url = `${API_BASE_URL}/${API_BASE_VERSION}/xlsx?${requestParams}`;
  // Execute request
  await baseHttpRequest('get', url, undefined, async (response: Response) => {
    const fileUrl = window.URL.createObjectURL(new Blob([await response.blob()]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute(
      'download',
      `software-catalogue-export-${new Date().toLocaleDateString().replace(/[/_]/g, '-')}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
  });
};

// #endregion

// -------------------------------------------
// OTHERS
// #region
// -------------------------------------------

const getValuesForFilters = async (): Promise<FiltersValues> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/uniqueValueOf/filters`);

const getContractTribes = async (): Promise<ListOfValues> =>
  Api.get(`${API_BASE_URL}/${API_BASE_VERSION}/uniqueValueOf/contracts?key=tribes`);

const getICContactFromEmail = async (ids: string[] | string): Promise<Record<string, IcContact>> => {
  const uniqueIds = typeof ids === 'string' ? [ids] : Array.from(new Set(ids));
  if (uniqueIds.length === 0) {
    return {};
  }
  const isHomologation = window.SGWTWidgetConfiguration.environment === 'homologation';
  const { contacts }: { contacts: IcContact[] } = await Api.get(
    `https://contacts-api${isHomologation ? '-hom' : ''}.fr.world.socgen/1.0/sg/contacts?ids=${uniqueIds.join(
      ','
    )}&fields=emails&sort=score`
  );

  return contacts.reduce((acc: Record<string, IcContact>, curr: IcContact) => {
    if (curr && curr.id) {
      return { ...acc, [curr.id]: curr };
    }
    return acc;
  }, {});
};

// #endregion

// -------------------------------------------
// Exported object, used in the code.
// -------------------------------------------

const ApiServices = {
  core: {
    get: Api.get,
    post: Api.post,
    put: Api.put,
    delete: Api.delete,
  },
  softwares: {
    getAll: getAllSoftwares,
    find: getSoftwares,
    getOne: getSoftware,
    getHistory: getSoftwareHistory,
    getSummary: getSoftwaresSummary,
    create: createSoftware,
    update: updateSoftware,
    delete: deleteSoftware,
  },
  contracts: {
    find: getContracts,
    getOne: getContract,
    getHistory: getContractHistory,
    getAssessments: getAssessments,
    create: createContract,
    update: saveContract,
    delete: deleteContract,
    changeSoftware: changeContractOfSoftware,
    assessContract: assessContract,
    getTribes: getContractTribes,
  },
  greenbook: {
    getAll: getGreenBook,
  },
  bluebook: {
    getAll: getBlueBook,
    create: createBlueBook,
    update: updateBlueBook,
    delete: deleteBlueBook,
    importExcel: importBlueBookFile,
  },
  excel: {
    importFile: importXlsxFile,
    exportFile: exportXlsxFile,
  },
  referentials: {
    getValuesForFilters: getValuesForFilters,
  },
  external: {
    ic: {
      getICContactFromEmail,
    },
  },
};

export default ApiServices;

import { useSgwtAuthentication } from '@sgwt/react-shared-libs';
import { Contract } from '../types/Contract';
import { Software } from '../types/Software';

export type Role = 'administration' | 'local-reviewer' | 'control-team';

const IAM_RESOURCE_ID = 'api.software-catalogue';
const PERMISSION_ADMINISTRATION = { resource: IAM_RESOURCE_ID, permission: 'administration' };
const PERMISSION_LOCAL_REVIEWER = { resource: IAM_RESOURCE_ID, permission: 'local-reviewer' };
const PERMISSION_CONTROL_TEAM = { resource: IAM_RESOURCE_ID, permission: 'control-team' };

interface SecurityOptions {
  contract?: Contract;
  software?: Software;
}

export function useUserRights(options?: SecurityOptions) {
  const { hasPermission, currentUser } = useSgwtAuthentication();

  const isAdmin = hasPermission(PERMISSION_ADMINISTRATION);
  const isLocalReviewer = hasPermission(PERMISSION_LOCAL_REVIEWER);
  const isControlTeam = hasPermission(PERMISSION_CONTROL_TEAM);
  const hasAnyPermission = isAdmin || isLocalReviewer || isControlTeam;

  const canEditContract = (): boolean => {
    if (hasAnyPermission) {
      return true;
    }
    return options?.contract?.architects.includes(currentUser?.contact_id ?? '') ?? false;
  };

  const canDeleteSoftware = (): boolean => {
    if (!options?.software) {
      return false;
    }
    return options.software.contracts.length === 0 && hasAnyPermission;
  };

  return {
    roles: {
      administrator: isAdmin,
      localReviewer: isLocalReviewer,
      controlTeam: isControlTeam,
      none: !isAdmin && !isLocalReviewer && !isControlTeam,
      some: isAdmin || isLocalReviewer || isControlTeam,
      every: isAdmin && isLocalReviewer && isControlTeam,
    },
    contracts: {
      creation: hasAnyPermission,
      edition: canEditContract(),
      deletion: canEditContract(),
    },
    softwares: {
      creation: hasAnyPermission,
      edition: hasAnyPermission,
      deletion: canDeleteSoftware(),
    },
    assessments: {
      accessToPage: isControlTeam || isLocalReviewer,
    },
    export: {
      canExport: hasAnyPermission,
    },
  };
}

import React from 'react';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { SgwtAuthenticationProvider, SGWTWidgetConfiguration } from '@sgwt/react-shared-libs';
import { SgwtConnect, SgwtSplashScreen, SgwtWebAnalytics, SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';
import i18next from 'i18next';
import en from './locales/en.json';
import fr from './locales/fr.json';
import App from './App';
import { GlobalConfiguration } from './config';

declare global {
  interface ImportMeta {
    env: Record<string, string>;
  }
  interface Window {
    SGWTWidgetConfiguration: SGWTWidgetConfiguration;
    globalConfiguration: GlobalConfiguration;
  }
}

function bootstrapI18N() {
  i18next.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    supportedLngs: ['en', 'fr'],
    lng: window.SGWTWidgetConfiguration.context.getLanguage() === 'fr' ? 'fr' : 'en',
    fallbackLng: 'en',
  });
}

const rootElement = document.querySelector('#root');
if (rootElement) {
  bootstrapI18N();
  const config = window.globalConfiguration;
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <SgwtWidgetContextProvider infrastructure="azure">
        <SgwtSplashScreen applicationId="software-catalogue" />
        <SgwtWebAnalytics
          siteId={config.matomo}
          apmRumEnabled={config.rum.activate}
          apmRumServerurl={config.rum.serverURL}
          apmRumServiceName={config.rum.serviceName}
        />
        <SgwtConnect
          clientId={config.sgConnect.clientId}
          authorizationEndpoint={config.sgConnect.endpoint}
          scope={config.sgConnect.scope}
          redirectUri="/silent-renew.html"
        />

        <SgwtAuthenticationProvider autoSignIn withSgmServiceLoader navigationAs={{ allowPermissionChange: true }}>
          <App />
        </SgwtAuthenticationProvider>
      </SgwtWidgetContextProvider>
    </React.StrictMode>
  );
}

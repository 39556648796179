import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export const ScrollToTop = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const navType = useNavigationType();
  useEffect(() => {
    if (navType !== 'POP') {
      window.scrollTo({ top: 0, left: 0 });
    }
  }, [location, navType]);
  return <>{children}</>;
};

export const GoToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setVisible(document.documentElement.scrollTop >= 100);
    };
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      {visible && (
        <button
          onClick={() => scrollToTop()}
          className="scroll-to-top btn btn-discreet-primary btn-icon btn-lg border-radius-16 shadow-xl position-fixed"
          style={{ right: '42px', bottom: '42px' }}
          title="Scroll to top"
          aria-label="Scroll to top"
        >
          <i className="icon">arrow_upward</i>
        </button>
      )}
    </>
  );
};

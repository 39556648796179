import React, { ReactNode } from 'react';
import { SgErrorPage } from '@sg-bootstrap/components/dist/react-library/src/components';

interface IErrorBoundaryState {
  error: Error | null;
}

interface IErrorBoundaryProps {
  children: ReactNode;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(errorDetected: Error, errorInfo: React.ErrorInfo) {
    console.error(
      `ErrorBoundary error[${errorDetected.message}] stack[${errorDetected.stack}] errorInfo[${errorInfo}]`
    );
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div style={{ height: '100vh' }}>
          <sgwt-help-center
            sg-connect-support="sg-connect-v2"
            application-id="software-catalogue"
            message-only="true"
            message-template={`Hello support.
            
I encountered an issue while using the Softwares Catalogue service.
-----------------
Technical details
Error message: ${error.message}
Stack trace: ${error.stack}
            `}
          />

          <SgErrorPage
            code={500}
            action-button-label="Back to explore"
            action-button-link="/"
            contact-us="help-center"
            fullscreen={true}
            description-label={error.message}
            title-label={`${document.title} - Error`}
          />
        </div>
      );
    }

    return children;
  }
}
